
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";

export default {
  name: "QuotationResponseEdit",
  props: {
    quotation: {
      type: Object,
      default: null,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const activeNames = ref(["1"]);
    const symbols = ref([{ id: "+" }, { id: "-" }]);
    const billing_concepts = ref({
      options: [] as any,
      list: [] as any,
    });
    const currencies = ref({
      options: [] as any,
      list: [] as any,
    });
    const headers = ref([
      { label: "icode", prop: "code" },
      { label: "idescription", prop: "description" },
      { label: "+/-", prop: "symbol" },
      { label: "currency_id", prop: "curency" },
      { label: "iunitprice", prop: "rate" },
      { label: "itotalunits", prop: "unit" },
      { label: "total", prop: "total" },
    ]);
    const headerTable = ref(headers.value);

    const createElement = () => {
      emit("createElement", props.quotation);
    };

    const removeElement = (index) => {
      emit("removeElement", {
        quotation: props.quotation,
        line_index: index,
      });
    };

    const selectDescription = (value, index) => {
      const concept = billing_concepts.value.list.find(
        (x) => x.id == value.description
      );
      emit("changeLineCode", {
        quotation: props.quotation,
        line_index: index,
        concept: concept,
      });
    };

    const getBillingConcepts = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/billingConcepts").then(({ data }) => {
        billing_concepts.value.list = data.billing_concepts;
        billing_concepts.value.options = data.billing_concepts;
      });
    };

    const selectBillingConcept = (query) => {
      if (query !== "") {
        setTimeout(() => {
          billing_concepts.value.options = billing_concepts.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!billing_concepts.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/billingConcepts/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              billing_concepts.value.list = data.billing_concepts;
              billing_concepts.value.options = data.billing_concepts;
            });
          }
        }, 200);
      } else {
        billing_concepts.value.options = [];
      }
    };

    const getCurrencies = () => {
      store.commit("setLoading", false);
      ApiService.query(`/api/currencies/lists`, {
        params: {
          per_page: 25,
          name: "",
        },
      }).then(({ data }) => {
        currencies.value.list = data.currencies;
        currencies.value.options = data.currencies;
      });
    };

    const selectCurrency = (query) => {
      if (query !== "") {
        setTimeout(() => {
          currencies.value.options = currencies.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!currencies.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/currencies/lists`, {
              params: {
                per_page: 25,
                name: query,
              },
            }).then(({ data }) => {
              currencies.value.list = data.currencies;
              currencies.value.options = data.currencies;
            });
          }
        }, 200);
      } else {
        currencies.value.options = [];
      }
    };

    onMounted(() => {
      getCurrencies();
      getBillingConcepts();
    });

    return {
      activeNames,
      headers,
      headerTable,
      billing_concepts,
      currencies,
      symbols,
      createElement,
      removeElement,
      selectBillingConcept,
      selectDescription,
      selectCurrency,
    };
  },
};
