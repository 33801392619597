
import ModeSelect from "@/components/catalogs-select/ModeSelect.vue";
import FlowSelect from "@/components/catalogs-select/FlowSelect.vue";
import LocationsSelect from "@/components/catalogs-select/LocationsSelect.vue";
import PortsSelect from "@/components/catalogs-select/PortsSelect.vue";
import UserOfficesSelect from "@/components/catalogs-select/UserOfficesSelect.vue";
import AddRegularLine from "@/components/quotations/AddRegularLine.vue";
import AddFCLLine from "@/components/quotations/AddFCLLine.vue";
import QuotationResponseEdit from "@/components/quotations/QuotationResponseEdit.vue";
import MerchandiseTypeSelect from "@/components/catalogs-select/MerchandiseTypeSelect.vue";
import EquimpentSubtypesSelect from "@/components/catalogs-select/EquimpentSubtypesSelect.vue";
import CustomerEntityOfficeSelect from "@/components/catalogs-select/CustomerEntityOfficeSelect.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default {
  name: "OfferForm",
  components: {
    Field,
    ModeSelect,
    FlowSelect,
    LocationsSelect,
    PortsSelect,
    AddRegularLine,
    AddFCLLine,
    MerchandiseTypeSelect,
    QuotationResponseEdit,
    EquimpentSubtypesSelect,
    UserOfficesSelect,
    CustomerEntityOfficeSelect,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const activeNames = ref(["1", "2"]);
    const sync = ref([] as any);
    const form = ref({
      office_id: undefined,
      mode_id: undefined,
      flow_id: undefined,
      origen_id: undefined,
      pol: undefined,
      destiny_id: undefined,
      pod: undefined,
      start_date: "",
      end_date: "",
      entity_id: undefined,
      merchandise_id: undefined,
      units: null,
      unit_weight: null,
      large: null,
      height: null,
      width: null,
      cbn: null,
      class: "",
      un: "",
      equipment_id: undefined,
      transport_type: undefined,
      pedido: false,
      km: 0,
    });
    const form_values = ref({
      office: "",
      entity_id: "",
      mode: "",
      flow: "",
      origen: "",
      pol: "",
      destiny: "",
      pod: "",
      entity: "",
      km: "",
      transport_type: "",
    });
    const quotations = ref([] as any);
    const distance = ref([] as any);
    const equipments = computed(() => store.getters.EquipmentsQuotations);
    const detail_equipments = computed(() => store.getters.EquipmentsMF);
    const units = computed(() => store.getters.UnitsQuotations);
    const quotationResult = computed(() => store.getters.Quotations);
    const total_units = computed(() => {
      let sum = 0;
      units.value.forEach((x) => (sum += +x.units));
      return sum;
    });
    const total_weight = computed(() => {
      let sum = 0;
      units.value.forEach((x) => (sum += +x.unit_weight));
      return sum;
    });
    const total_cnb = computed(() => {
      let sum = 0;
      units.value.forEach((x) => (sum += +x.cbn));
      return sum;
    });
    const total_equip_units = computed(() => {
      let sum = 0;
      equipments.value.forEach((x) => (sum += +x.units));
      return sum;
    });
    const total_equip_weight = computed(() => {
      let sum = 0;
      equipments.value.forEach((x) => (sum += +x.unit_weight));
      return sum;
    });

    watch(
      () => [
        form.value.origen_id,
        form.value.pol,
        form.value.destiny_id,
        form.value.pod,
        form.value.flow_id,
      ],
      (value) => {
        onchangeCheckbox(false);
      }
    );

    const updateMode = (value) => {
      form.value.mode_id = value;
      form_values.value.mode = value;
    };

    const onchangeCheckbox = (value) => {
      if (!value) {
        if (
          form.value.flow_id == "i" &&
          form.value.pod !== undefined &&
          form.value.destiny_id !== undefined
        ) {
          const item = findItem(form.value.destiny_id, form.value.pod);
          form.value.km = item.length ? item[0].distance : 0;
        } else if (
          form.value.flow_id == "e" &&
          form.value.pol !== undefined &&
          form.value.origen_id !== undefined
        ) {
          const item = findItem(form.value.origen_id, form.value.pol);
          form.value.km = item.length ? item[0].distance : 0;
        }
      }
    };

    const findItem = (location_id, port_id) => {
      return distance.value.filter(
        (x) => x.location_id === location_id && x.port_id === port_id
      );
    };

    const addEquipment = () => {
      store.commit("addEquipmentsQuotation", {
        id: undefined,
        equipment_id: undefined,
        merchandise_id: undefined,
        unit_weight: 0,
        units: 0,
        temperature: 0,
      });
    };

    const removeEquipment = (index) => {
      store.commit("removeEquipmentsQuotation", index);
    };

    const addUnits = () => {
      store.commit("addUnitsQuotation", {
        id: undefined,
        merchandise_id: undefined,
        units: 0,
        unit_weight: 0,
        large: null,
        height: null,
        width: null,
        cbn: 0,
        class: null,
        un: null,
      });
    };

    const removeUnitst = (index) => {
      store.commit("removeUnitsQuotation", index);
    };

    const createQuotationLine = (quotation) => {
      const index = quotation.id;
      quotations.value[index].lines.push({
        code: "",
        description: "",
        symbol: "+",
        curency: "",
        rate: "",
        unit: "1",
        total: "1",
      });
    };

    const removeQuotationLine = (payload) => {
      const index = payload.quotation.id;
      const line_index = payload.line_index;
      quotations.value[index].lines.splice(line_index, 1);
    };

    const changeLineCode = (payload) => {
      const index = payload.quotation.id;
      const line_index = payload.line_index;
      const concept = payload.concept;
      quotations.value[index].lines[line_index].code = concept.code;
    };

    const formPayload = (element) => {
      const params = {
        date: form.value.end_date,
        cost_type_id: 1,
        office: form.value.office_id,
        conditions: [] as any,
      };

      //MODE
      if (form.value.mode_id !== undefined) {
        const index = sync.value.findIndex((x) => x.description === "modo");
        if (index !== -1) {
          const temp = {
            criteria_id: sync.value[index].criteria_id,
            value: form.value.mode_id,
          };
          params.conditions.push(temp);
        }
      }
      //FLOW
      if (form.value.flow_id !== undefined) {
        const index = sync.value.findIndex((x) => x.description === "flujo");
        if (index !== -1) {
          const temp = {
            criteria_id: sync.value[index].criteria_id,
            value: form.value.flow_id,
          };
          params.conditions.push(temp);
        }
      }
      //ORIGEN
      if (form.value.origen_id !== undefined) {
        const index = sync.value.findIndex((x) => x.description === "origen");
        if (index !== -1) {
          const temp = {
            criteria_id: sync.value[index].criteria_id,
            value: form.value.origen_id,
          };
          params.conditions.push(temp);
        }
      }
      //POL
      if (form.value.pol !== undefined) {
        const index = sync.value.findIndex((x) => x.description === "pol");
        if (index !== -1) {
          const temp = {
            criteria_id: sync.value[index].criteria_id,
            value: form.value.pol,
          };
          params.conditions.push(temp);
        }
      }
      //POL
      if (form.value.pod !== undefined) {
        const index = sync.value.findIndex((x) => x.description === "pod");
        if (index !== -1) {
          const temp = {
            criteria_id: sync.value[index].criteria_id,
            value: form.value.pod,
          };
          params.conditions.push(temp);
        }
      }
      //DESTINO
      if (form.value.origen_id !== undefined) {
        const index = sync.value.findIndex((x) => x.description === "destino");
        if (index !== -1) {
          const temp = {
            criteria_id: sync.value[index].criteria_id,
            value: form.value.destiny_id,
          };
          params.conditions.push(temp);
        }
      }
      //CUSTOMER
      if (form.value.entity_id !== undefined) {
        const index = sync.value.findIndex((x) => x.description === "cliente");
        if (index !== -1) {
          const temp = {
            criteria_id: sync.value[index].criteria_id,
            value: form.value.pod,
          };
          params.conditions.push(temp);
        }
      }
      //DISTANCE
      if (form.value.km !== undefined) {
        const index = sync.value.findIndex(
          (x) => x.description === "distancia"
        );
        if (index !== -1) {
          const temp = {
            criteria_id: sync.value[index].criteria_id,
            value: form.value.km,
          };
          params.conditions.push(temp);
        }
      }
      //EQUIPMENTS
      if (form.value.mode_id === "imaritimefcl" && element) {
        const index = sync.value.findIndex(
          (x) => x.description === "equipamiento"
        );
        if (index !== -1) {
          //EQUIPMENT
          const temp = {
            criteria_id: sync.value[index].criteria_id,
            value: element.equipment_id,
          };
          params.conditions.push(temp);
          //MERCHANDISE
          const merch_index = sync.value.findIndex(
            (x) => x.description === "imo"
          );
          if (merch_index !== -1) {
            const temp = {
              criteria_id: sync.value[merch_index].criteria_id,
              value: element.merchandise_id == "dangerous" ? 1 : 0,
            };
            params.conditions.push(temp);
          }
          //UNITS
          const units_index = sync.value.findIndex(
            (x) => x.description === "unidades"
          );
          if (units_index !== -1) {
            const temp = {
              criteria_id: sync.value[units_index].criteria_id,
              value: element.units,
            };
            params.conditions.push(temp);
          }
          //UNIT WEIGHT
          const unit_weight_index = sync.value.findIndex(
            (x) => x.description === "peso"
          );
          if (unit_weight_index !== -1) {
            const temp = {
              criteria_id: sync.value[unit_weight_index].criteria_id,
              value: element.unit_weight,
            };
            params.conditions.push(temp);
          }
        }
      }
      //UNITS
      else if (form.value.mode_id === "imaritimelcl" && element) {
        const index = sync.value.findIndex(
          (x) => x.description === "mercancia"
        );
        if (index !== -1) {
          //MERCHANDISE
          const temp = {
            criteria_id: sync.value[index].criteria_id,
            value: element.merchandise_id == "dangerous" ? 1 : 0,
          };
          params.conditions.push(temp);
          //UNITS
          const units_index = sync.value.findIndex(
            (x) => x.description === "unidades"
          );
          if (units_index !== -1) {
            const temp = {
              criteria_id: sync.value[units_index].criteria_id,
              value: element.units,
            };
            params.conditions.push(temp);
          }
          //UNIT WEIGHT
          const unit_weight_index = sync.value.findIndex(
            (x) => x.description === "peso"
          );
          if (unit_weight_index !== -1) {
            const temp = {
              criteria_id: sync.value[unit_weight_index].criteria_id,
              value: element.unit_weight,
            };
            params.conditions.push(temp);
          }
          //LARGE
          const large_index = sync.value.findIndex(
            (x) => x.description === "largo"
          );
          if (large_index !== -1) {
            const temp = {
              criteria_id: sync.value[large_index].criteria_id,
              value: element.large,
            };
            params.conditions.push(temp);
          }
          //HEIGHT
          const height_index = sync.value.findIndex(
            (x) => x.description === "alto"
          );
          if (height_index !== -1) {
            const temp = {
              criteria_id: sync.value[height_index].criteria_id,
              value: element.height,
            };
            params.conditions.push(temp);
          }
          //WIDTH
          const width_index = sync.value.findIndex(
            (x) => x.description === "ancho"
          );
          if (width_index !== -1) {
            const temp = {
              criteria_id: sync.value[width_index].criteria_id,
              value: element.width,
            };
            params.conditions.push(temp);
          }
          //CBN
          const cbn_index = sync.value.findIndex(
            (x) => x.description === "cbn"
          );
          if (cbn_index !== -1) {
            const temp = {
              criteria_id: sync.value[cbn_index].criteria_id,
              value: element.cbn,
            };
            params.conditions.push(temp);
          }
        }
      }
      return params;
    };

    const addDistance = () => {
      if (form.value.pedido && form.value.km > 0) {
        if (
          form.value.flow_id == "i" &&
          form.value.pod !== undefined &&
          form.value.destiny_id !== undefined
        ) {
          const item = findItem(form.value.destiny_id, form.value.pod);
          if (!item.length) {
            const params = {
              location_id: form.value.destiny_id,
              port_id: form.value.pod,
              distance: form.value.km,
            };
            ApiService.post(`/api/distances`, params).then(({ data }) => {
              distance.value.push({
                id: data.id,
                location_id: data.location_id,
                port_id: data.port_id,
                distance: data.distance,
              });
            });
          }
        } else if (
          form.value.flow_id == "e" &&
          form.value.pol !== undefined &&
          form.value.origen_id !== undefined
        ) {
          const item = findItem(form.value.origen_id, form.value.pol);
          if (!item.length) {
            const params = {
              location_id: form.value.origen_id,
              port_id: form.value.pol,
              distance: form.value.km,
            };
            ApiService.post(`/api/distances`, params).then(({ data }) => {
              distance.value.push({
                id: data.id,
                location_id: data.location_id,
                port_id: data.port_id,
                distance: data.distance,
              });
            });
          }
        }
      }
    };

    const onSubmit = () => {
      quotations.value = [] as any;
      store.commit("resetQuotaton");
      addDistance();
      if (form.value.mode_id == "imaritimefcl") {
        equipments.value.forEach((x, index_e) => {
          let lines = [] as any;
          const params = formPayload(x);
          ApiService.post("/api/tariff_oficial/search-rates", params).then(
            ({ data }) => {
              if (
                data !==
                "No existen tarifas de nivel obligatorio FLETE para las condiciones solicitadas"
              ) {
                const temp = Object.entries(data.price)[0] as any;

                data.tariffs.forEach((y) => {
                  lines.push({
                    code: y.billing_concept.code,
                    description: y.billing_concept.name,
                    symbol: y.rates[0].is_surcharge === 1 ? "+" : "-",
                    curency: y.currency.name,
                    rate: y.price,
                    unit: x.units,
                    total: y.price * x.units,
                  });

                  y.conditions.forEach((c, index) => {
                    const criteria = sync.value.find(
                      (z) => z.criteria_id === c.criteria_id
                    );
                    switch (criteria.description) {
                      case "modo":
                        y.conditions[index].show = form_values.value.mode;
                        break;
                      case "flujo":
                        y.conditions[index].show = form_values.value.flow;
                        break;
                      case "origen":
                        y.conditions[index].show = form_values.value.origen;
                        break;
                      case "pol":
                        y.conditions[index].show = form_values.value.pol;
                        break;
                      case "pod":
                        y.conditions[index].show = form_values.value.pod;
                        break;
                      case "destino":
                        y.conditions[index].show = form_values.value.destiny;
                        break;
                      case "cliente":
                        y.conditions[index].show = form_values.value.entity;
                        break;
                      case "distancia":
                        y.conditions[index].show = form_values.value.km;
                        break;
                      case "equipamiento":
                        var equip = detail_equipments.value.find(
                          (e) => e.id === x.equipment_id
                        );
                        y.conditions[index].show = equip.name;
                        break;
                    }
                  });
                });

                const equipment_temp = detail_equipments.value.filter(
                  (z) => z.id == x.equipment_id
                );
                quotations.value.push({
                  id: index_e,
                  equipment: equipment_temp.length
                    ? equipment_temp[0].name
                    : "",
                  total: `${temp[1] * x.units} ${temp[0]}`,
                  lines: lines,
                });

                //Save Quotation
                if (form.value.entity_id) {
                  const payload = {
                    data: data,
                    units: x.units,
                  };
                  store.commit("setQuotation", payload);
                }
              }
            }
          );
        });
      } else {
        units.value.forEach((x) => {
          let lines = [] as any;
          const params = formPayload(x);
          ApiService.post("/api/tariff_oficial/search-rates", params).then(
            ({ data }) => {
              if (
                data !==
                "No existen tarifas de nivel obligatorio FLETE para las condiciones solicitadas"
              ) {
                const temp = Object.entries(data.price)[0] as any;
                data.tariffs.forEach((y) => {
                  lines.push({
                    code: y.billing_concept.code,
                    description: y.billing_concept.name,
                    symbol: y.rates[0].is_surcharge === 1 ? "+" : "-",
                    curency: y.currency.name,
                    rate: y.price,
                    unit: x.units,
                    total: y.price * x.units,
                  });

                  y.conditions.forEach((c, index) => {
                    const criteria = sync.value.find(
                      (z) => z.criteria_id === c.criteria_id
                    );
                    switch (criteria.description) {
                      case "modo":
                        y.conditions[index].show = form_values.value.mode;
                        break;
                      case "flujo":
                        y.conditions[index].show = form_values.value.flow;
                        break;
                      case "origen":
                        y.conditions[index].show = form_values.value.origen;
                        break;
                      case "pol":
                        y.conditions[index].show = form_values.value.pol;
                        break;
                      case "pod":
                        y.conditions[index].show = form_values.value.pod;
                        break;
                      case "destino":
                        y.conditions[index].show = form_values.value.destiny;
                        break;
                      case "cliente":
                        y.conditions[index].show = form_values.value.entity;
                        break;
                      case "distancia":
                        y.conditions[index].show = form_values.value.km;
                        break;
                      case "equipamiento":
                        var equip = detail_equipments.value.find(
                          (e) => e.id === x.equipment_id
                        );
                        y.conditions[index].show = equip.name;
                        break;
                    }
                  });
                });

                const equipment_temp = detail_equipments.value.filter(
                  (z) => z.id == x.equipment_id
                );
                quotations.value.push({
                  equipment: equipment_temp.length
                    ? equipment_temp[0].name
                    : "",
                  total: `${temp[1] * x.units} ${temp[0]}`,
                  lines: lines,
                });

                //Save Quotation
                if (form.value.entity_id) {
                  const payload = {
                    data: data,
                    units: x.units,
                  };
                  store.commit("setQuotation", payload);
                }
              }
            }
          );
        });
      }
    };

    const onSave = () => {
      if (form.value.entity_id && quotationResult.value.length) {
        quotationResult.value.forEach((x) => {
          saveQuote(x);
        });
      }
    };

    const getDistance = () => {
      ApiService.get(`/api/distances?page=1&per_page=100000`).then(
        ({ data }) => {
          distance.value = data.distances;
        }
      );
    };

    const saveQuote = (params) => {
      let tariffs = [] as any;
      params.data.tariffs.forEach((x) => {
        const temp_tariff = {
          id: x.tariff.id,
          name: x.tariff.name,
        };
        tariffs.push({
          billing_concept: x.billing_concept,
          tariff: temp_tariff,
          price: x.price * params.units,
          conditions: x.conditions,
          rates: x.rates,
          currency: x.currency,
        });
      });
      const temp_price = Object.entries(params.data.price)[0] as any;
      const payload = {
        code: Math.random().toString(10).substr(2, 8),
        created_date: form.value.start_date,
        expire_date: form.value.end_date,
        price: temp_price[1] * params.units,
        entity_id: form.value.entity_id,
        office_id: form.value.office_id,
        units: params.units,
        tariffs: tariffs,
      };
      ApiService.post("/api/quote", payload).then(({ data }) => {
        // console.log("data", data);
      });
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("", [
        { route: "/manage_offers", label: "manage_offers" },
      ]);
      ApiService.get(`/api/synchronize_criterias`).then(({ data }) => {
        sync.value = data;
      });
      getDistance();
      addEquipment();
      addUnits();
    });

    return {
      form,
      form_values,
      activeNames,
      equipments,
      units,
      sync,
      distance,
      total_units,
      total_weight,
      total_cnb,
      total_equip_units,
      total_equip_weight,
      quotations,
      onchangeCheckbox,
      addEquipment,
      addUnits,
      removeEquipment,
      removeUnitst,
      onSubmit,
      onSave,
      updateMode,
      createQuotationLine,
      removeQuotationLine,
      changeLineCode,
    };
  },
};
